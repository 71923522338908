<template>
  <div id="home" class="text-center">
    <div class="flex min-h-screen flex-col p-8">
      <div class="m-auto">
        <div class="mb-12 mx-auto">
          <img alt="YellowCat Development Logo" src="../assets/ycd_logo_small.png" class="w-48 mx-auto">
          <p class="font-semibold text-xl">YellowCat<br>Development</p>
        </div>
        <div class="grid grid-cols-2 md:flex md:flex-row space-y-0 md:space-x-4 items-center gap-4">
          <img alt="StadtbahnfahrerClub Logo" src="https://api.stadtbahnfahrer.club/assets/111cab68-8185-4e21-a8ba-a885627bf114" class="w-24">
          <img alt="OmnibusfahrerClub Logo" src="https://api.omnibusfahrer.club/assets/de896525-5583-43a4-82c4-d2f8072bf14a" class="w-24">
          <img alt="MyOufits Logo" src="../assets/myoutfits.png" class="w-24 rounded-md">
          <img alt="CardDuell Logo" src="../assets/cardduel.png" class="w-24 rounded-md">
        </div>
      </div>
      <div class="">
        <p class="font-medium"><a class="link" href="mailto:hallo@yellowcat.dev">Schreibe uns eine E-Mail</a></p>
      </div>
    </div>

    <!-- <div class="flex h-screen flex-col p-8">
      <div class=" m-auto">
        <div class="">
          <h1 class="font-semibold text-yellow-300 text-3xl">Unsere Projekte</h1>
        </div>
        <div class="flex flex-row space-x-4">
          <img alt="YellowCat Development Logo" src="../assets/stadtbahnfahrerclub.png" class="w-24">
          <img alt="YellowCat Development Logo" src="../assets/omnibusfahrerclub.png" class="w-24">
          <img alt="YellowCat Development Logo" src="../assets/myoutfits.png" class="w-24 rounded-md">
          <img alt="YellowCat Development Logo" src="../assets/cardduel.png" class="w-24 rounded-md">
        </div>
      </div>
    </div> -->
    <div class="flex h-screen flex-col p-8">
      <div class="flex items-center m-auto flex-col">
        <iframe src="https://giphy.com/embed/JIX9t2j0ZTN9S" class="giphy-embed w-72 h-72 md:w-96 md:h-96" frameBorder="0" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/JIX9t2j0ZTN9S">via GIPHY</a></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  }
}
</script>
